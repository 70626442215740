import wpAddCart
    from "@scripts/lib/wpAddCart";
import toastBox
    from "@scripts/lib/toast";


const initDocRegForm = function () {
    let formRoot = $('#doctor-registration-form');
    let context = window.__CONTEXT__ || {
        messages: {}
    };
    let checkForm = function (itemName) {
        let checkAll = !itemName;
        let valid = true;


        if (checkAll || itemName === 'name') {
            let nameInput = formRoot.find('.js-name-input');
            let name = nameInput.val();
            name = ('' + name).trim();
            if (!name) {
                let errMsg = context.messages['error_no_name'];
                nameInput.closest('.input').addClass('error').find('.error-message').text(errMsg);
                valid = false;
                if (!checkAll) {
                    return false;
                }
            } else {
                nameInput.closest('.input').removeClass('error');
            }
        }
        if (checkAll || itemName === 'email') {
            let emailInput = formRoot.find('.js-email-input');
            let email = emailInput.val();
            email = ('' + email).trim();
            if (!email || !email.includes('@')) {
                let errMsg = context.messages['error_invalid_email'];
                emailInput.closest('.input').addClass('error').find('.error-message').text(errMsg);
                valid = false;
                if (!checkAll) {
                    return false;
                }
            } else {
                emailInput.closest('.input').removeClass('error');
            }
        }
        if (checkAll || itemName === 'phone') {
            let mobileInput = formRoot.find('.js-phone-input');
            let mobile = mobileInput.val();
            mobile = ('' + mobile).trim();
            mobileInput.closest('.input').removeClass('error');
            /*if (!mobile) {
                let errMsg = context.messages['error_no_phone_no'];
                mobileInput.closest('.input').addClass('error').find('.error-message').text(errMsg);
                valid = false;
                if (!checkAll) {
                    return false;
                }
            } else {
                
            }*/
        }
        if (checkAll || itemName === 'licence_number') {
            let licenceInput = formRoot.find('.js-licence_number-input');
            let licence = licenceInput.val();
            licence = ('' + licence).trim();
            licenceInput.closest('.input').removeClass('error');
            /*if (!licence) {
                let errMsg = context.messages['error_no_licence_number'];
                licenceInput.closest('.input').addClass('error').find('.error-message').text(errMsg);
                valid = false;
                if (!checkAll) {
                    return false;
                }
            } else {
                
            }*/
        }
        if (checkAll || itemName === 'licence_type') {
            let licenceSelect = formRoot.find('.js-licence_type-input');
            let licence = licenceSelect.val();
            licence = ('' + licence).trim();
            licenceSelect.closest('.input').removeClass('error');
            /*if (!licence) {
                let errMsg = context.messages['error_no_licence_type'];
                licenceSelect.closest('.input').addClass('error').find('.error-message').text(errMsg);
                valid = false;
                if (!checkAll) {
                    return false;
                }
            } else {
                
            }*/
        }
        if (checkAll || itemName === 'institution_name') {
            let institutionNameInput = formRoot.find('.js-institution_name-input');
            let institutionName = institutionNameInput.val();
            institutionName = ('' + institutionName).trim();
            institutionNameInput.closest('.input').removeClass('error');

        }


        if (valid) {
            return {
                name: formRoot.find('.js-name-input').val(),
                email: formRoot.find('.js-email-input').val(),
                mobile: formRoot.find('.js-phone-input').val(),
                licence_number: formRoot.find('.js-license_number-input').val(),
                licence_type: formRoot.find('.js-license_type-input').val(),
                institution_name: formRoot.find('.js-institution_name-input').val(),
            }
        }
        return valid;
    };


    //init name input
    let nameInput = formRoot.find('.js-name-input');
    nameInput.on('keyup', function () {
        checkForm('name');
    });


    //init email input
    let emailInput = formRoot.find('.js-email-input');
    emailInput.on('keyup', function () {
        checkForm('email');
    });

    //init mobile input
    let phoneInput = formRoot.find('.js-phone-input');
    phoneInput.on('keyup', function () {
        checkForm('mobile');
    });

    //init licence input
    let licenceNumberInput = formRoot.find('.js-license_number-input');
    licenceNumberInput.on('keyup', function () {
        checkForm('licence_number');
    });
    let licenceTypeSelect = formRoot.find('.js-license_type-input');
    licenceTypeSelect.on('change', function () {
        checkForm('license_type');
    });

    //init institution_name
    let institutionNameInput = formRoot.find('.js-institution_name-input');
    institutionNameInput.on('keyup', function () {
        checkForm('institution_name');
    });


    formRoot.find('.js-submit').on('click', function (e) {
        e.preventDefault();
        let $this = $(this);
        let formData = checkForm();
        if (!formData) {
            return;
        }
        console.log('submit', formData)
        /* toastBox.show({
             type: 'text',
             content: 'Application Received',
             hideLoading: true
 
         }, 0);*/
        toastBox.show('Loading...', 0);
        $.ajax({
            url: location.origin + location.pathname + '/webapi/registerDoctor',
            method: 'POST',
            data: {
                "name": formData.name,
                "phone": formData.mobile,
                "email": formData.email,
                "license_number": formData.licence_number,
                "license_type": formData.license_type,
                "institution_name": formData.institution_name,

            },
            success: function (res) {
                console.log('res', res);
                if (res.status === 'ok') {
                    // show success
                    formRoot.find('.js-name-input').val('');
                    formRoot.find('.js-email-input').val('');
                    formRoot.find('.js-mobile-input').val('');
                    formRoot.find('.js-license_number-input').val('');
                    formRoot.find('.js-license_type-input').val('');
                    formRoot.find('.js-institution_name-input').val('');
                    $('#register-success').show();
                } else {
                    // show error message @phone_input
                    let errMsg = context.messages['error_server_error'];
                    // formRoot.find('.js-license_type-input').closest('.input').addClass('error').find('.error-message').text(errMsg);
                    // $this.closest('.input-submit').addClass('error').find('.error-message').text(errMsg);
                    $('#register-fail .js-error').text(errMsg);
                    $('#register-fail').show();
                }
            },
            error: function (res) {
                console.log('res', typeof res.responseJSON, res.responseJSON);
                let resJson = res.responseJSON || {};
                let errMsg = resJson.message || context.messages['error_server_error'];
                // formRoot.find('.license_type').closest('.input').addClass('error').find('.error-message').text(errMsg);
                // $this.closest('.input-submit').addClass('error').find('.error-message').text(errMsg);
                $('#register-fail .js-error').text(errMsg);
                $('#register-fail').show();
            },
            complete: function () {
                toastBox.hide();
            }
        });
    });
};

const initCarousel = function() {
    var domRoot = $('#carousel-root');
    var container = domRoot.find('.js-scroll-container');
    var scrollDom = domRoot.find('.js-scroll');
    var scrollDomItem = domRoot.find('.js-scroll-item:first');
    var dots = domRoot.find('.js-dot');
    let itemWidth = scrollDomItem.width();
    let currentIndex = 0;
    let maxIndex = dots.length - 1;
    
    // 初始化滚动位置和第一个点的状态
    container.scrollLeft(0);
    dots.eq(0).addClass('active');

    // 更新点的状态
    function updateDots(index) {
        dots.removeClass('active');
        dots.eq(index).addClass('active');
    }

    // 滚动到指定索引
    function scrollToIndex(index, reverse = false) {
        if (reverse) {
            // 当需要从最后一个滚动到第一个时
            // 先将内容复制一份放在最后
            let firstItem = scrollDom.find('.js-scroll-item:first').clone();
            scrollDom.append(firstItem);
            
            // 滚动到复制的元素
            container.animate({
                scrollLeft: (maxIndex + 1) * itemWidth
            }, 300, function() {
                // 动画完成后，立即将滚动位置重置到第一个原始元素
                container.scrollLeft(0);
                // 移除临时复制的元素
                firstItem.remove();
            });
        } else {
            container.animate({
                scrollLeft: index * itemWidth
            }, 300);
        }
        updateDots(index);
        currentIndex = index;
    }

    // 监听点击事件
    dots.on('click', function() {
        let index = $(this).index();
        scrollToIndex(index);
    });

    // 监听滚动事件
    container.on('scroll', function() {
        let scrollLeft = $(this).scrollLeft();
        let index = Math.round(scrollLeft / itemWidth);
        if (index !== currentIndex) {
            updateDots(index);
            currentIndex = index;
        }
    });

    // 自动播放
    let autoplayInterval;
    function startAutoplay() {
        autoplayInterval = setInterval(function() {
            let nextIndex = currentIndex + 1;
            if (nextIndex > maxIndex) {
                nextIndex = 0;
                scrollToIndex(nextIndex, true); // 使用 reverse 效果
            } else {
                scrollToIndex(nextIndex);
            }
        }, 5000);
    }

    function stopAutoplay() {
        clearInterval(autoplayInterval);
    }

    // 鼠标悬停时暂停自动播放
    container.on('mouseenter', stopAutoplay);
    container.on('mouseleave', startAutoplay);

    // 触摸事件处理
    let touchStartX = 0;
    let touchStartScrollLeft = 0;
    
    scrollDom.on('touchstart', function(e) {
        touchStartX = e.touches[0].pageX;
        touchStartScrollLeft = scrollDom.scrollLeft();
        stopAutoplay();
    });

    scrollDom.on('touchmove', function(e) {
        e.preventDefault();
        let touchCurrentX = e.touches[0].pageX;
        let diff = touchStartX - touchCurrentX;
        scrollDom.scrollLeft(touchStartScrollLeft + diff);
    });

    scrollDom.on('touchend', function() {
        let scrollLeft = scrollDom.scrollLeft();
        let index = Math.round(scrollLeft / itemWidth);
        if (index > maxIndex) index = 0;
        scrollToIndex(index);
        startAutoplay();
    });

    // 开始自动播放
    startAutoplay();
};

$(function () {
    initCarousel();
    $('#packet-plans .js-buy-now').click(function () {
        var itemId = $(this).attr('data-item');
        var variationId = $(this).attr('data-variation');
        var variationName = $(this).attr('data-name');
        wpAddCart.addToCard(window.__BUY_PATH__, window.__CHECKOUT_PATH__, itemId, variationId, 1, null);
    });

    var scrollTo = function (query) {
        if ($(query).get(0).scrollIntoView) {
            $(query).get(0).scrollIntoView({behavior: 'smooth'});
        } else {
            $('html, body').animate({
                scrollTop: $(query).offset().top
            }, 'slow');
        }
    }

    if (window.location.hash === '#packet-plans') {
        setTimeout(() => {
            scrollTo('#packet-plans')
        }, 200);
    }
    $('#buy-packages').click(function () {
        window.location.hash = 'packet-plans';
        scrollTo('#packet-plans');
    });

    if (window.location.hash === '#doctor-registration') {
        setTimeout(() => {
            scrollTo('#doctor-registration')
        }, 200);
    }
    $('#doctor-reg').click(function () {
        window.location.hash = 'doctor-registration';
        scrollTo('#doctor-registration');
    });
    $('#download_brochure_btn').click(function () {
        window.location.hash = 'download_brochure';
        scrollTo('#download_brochure');
    });

    $('#register-success .js-confirm').click(function () {
        $('#register-success').hide();
    });
    $('#register-fail .js-confirm').click(function () {
        $('#register-fail').hide();
    });

    initDocRegForm();
})