import toastBox
    from "@scripts/lib/toast";

const checkout = function (buyPath, checkoutPath, itemId, variantId, quantity, otherFields) {
    toastBox.show('Loading...', 0);
    let ifr = $('<iframe style="position: fixed;width: 1px;height:1px;left:-100px;top:-100px;" name="update_cart" id="update_cart"></iframe>');
    ifr.appendTo('body');
    ifr.on('load', function () {
        toastBox.hide();
        // window.open(checkoutPath, '_blank');
        location.href = checkoutPath || 'https://buzud.com/en/checkout/';
    });
    quantity = quantity || 1;
    let form = $('<form></form>');
    form.attr('action', buyPath);
    form.attr('method', 'post');
    form.attr('enctype', 'multipart/form-data');
    form.attr('target', 'update_cart');
    //add-to-cart: 4821
    // product_id: 4821
    // variation_id: 7715

    //quantity: 1
    // add-to-cart: 11142
    form.append('<input type="hidden" name="quantity" value="' + quantity + '">');
    form.append('<input type="hidden" name="add-to-cart" value="' + itemId + '">');
    form.append('<input type="hidden" name="product_id" value="' + itemId + '">');
    if (variantId) {
        form.append('<input type="hidden" name="variation_id" value="' + variantId + '">');
    }
    if (typeof otherFields === 'object') {
        for (let key in otherFields) {
            form.append('<input type="hidden" name="' + key + '" value="' + otherFields[key] + '">');
        }
    }
    // form.append('<input type="hidden" name="product_id" value="4821">');
    // form.append('<input type="hidden" name="variation_id" value="7715">');
    form.appendTo('body');
    form.submit();
};


export default {
    addToCard: checkout,
};